/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "W 2023 roku toczyły się prace nad zadaniem obejmującym wykonanie robót budowlanych na odcinku 1775 m - od skrzyżowania z drogą wojewódzką nr 256 we Włókach w kierunku Gądecza. (ETAP I od km 0+000 – do km 1+775)."), "\n", React.createElement(_components.p, null, "W ramach prac budowlanych wykonano następujące zadania:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "wykonanie nowej jezdni o nawierzchni z betonu asfaltowego"), "\n", React.createElement(_components.li, null, "budowa zatok autobusowych o nawierzchni betonowej, zjazdów"), "\n", React.createElement(_components.li, null, "budowę poboczy gruntowych, chodników z kostki, drogi rowerowej o nawierzchni bitumicznej"), "\n", React.createElement(_components.li, null, "budowę wyspy dzielącej typu mała kropla na wlocie DP1507C przy skrzyżowaniu z DW256,"), "\n", React.createElement(_components.li, null, "wykonanie wybrukowań przy skrzyżowaniu z DW256 oraz wlotach dróg gminnych i wewnętrznych z kostki granitowej"), "\n", React.createElement(_components.li, null, "wykonanie ściany oporowej przy drodze rowerowej we Włókach"), "\n", React.createElement(_components.li, null, "budowę kanalizacji deszczowej, przebudową sieci wod-kan, energetycznej i telekomunikacyjnej"), "\n", React.createElement(_components.li, null, "budowę kanału technologicznego"), "\n", React.createElement(_components.li, null, "wykonanie nasadzeń zastępczych oraz zieleni w postaci trawników"), "\n", React.createElement(_components.li, null, "dla poprawy bezpieczeństwa pieszych i rowerzystów, na przejściach dla pieszych zaprojektowano oświetlenie uliczne LED, znaki D6, D6a i D6b na fluorescencyjnym tle oraz płytki wskaźnikowe/ostrzegawcze."), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
